<template>
  <div>
    <div class="row">
      <div class="col-md-9">
        <!-- <h1>Sites</h1> -->
      </div>
      <div class="col-md-3">
        <templates></templates>
      </div>
    </div>
    <Grid
      ref="grid"
      :data-items="result"
      :filterable="true"
      :filter="filter"
      @filterchange="filterChange"
      :edit-field="'inEdit'"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :page-size="pageSize"
      :total="total"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      @sortchange="sortChangeHandler"
      @itemchange="itemChange"
      @dataStateChange="dataStateChange"
      :columns="columns"
      @pagechange="pageChange"
      :selected-field="selectedField"
      @rowclick="onRowClick"
      :row-render="cellFunction"
    >
      <!-- add other buttons here -->
      <template v-slot:myCellTemplate="{ props }">
        <td>
          <div v-if="props.dataItem.name">
            <router-link :to="'/sites/' + props.dataItem.id">
              {{ props.dataItem[props.field] }}
            </router-link>
            <div>
              <div
                id="inverter_link_button"
                @click="
                  openWindow(`/sites/` + props.dataItem.id + `/inverters_list`)
                "
              >
                <img
                  :src="require('@/assets/img/inverters-link.svg')"
                  width="20"
                  height="20"
                  class="mt-1"
                />
                <!-- <v-icon size="24">mdi-car-battery</v-icon> -->
              </div>
              <div
                id="monitoring_link_button"
                class="mt-1"
                size="24"
                @click="openWindow(props.dataItem.monitoring_url)"
              >
                <img
                  :src="
                    require('@/assets/img/' +
                      props.dataItem.monitoring_system +
                      '.png')
                  "
                />
                <!-- <img :src="'@/assets/img/' + props.dataItem.monitoring_system + '.png'" /> -->
              </div>
              <div v-if="props.dataItem.sitePlans" id="site_plans_button">
                <v-icon size="24" @click="openWindow(props.dataItem.sitePlans)">
                  mdi-floor-plan
                </v-icon>
              </div>
            </div>
          </div>
          <div v-else>
            {{ props.dataItem[props.field] }}
          </div>
        </td>
      </template>
<grid-toolbar class="d-flex justify-space-between">
    <!-- <div class="toolbar-left">
        

        <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
            id="export_btn"
            @click="exportExcel"
        >
            Export to Excel
        </v-btn>
    </div> -->

    <!-- Vuetify Menu for More Options -->
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
                More Options
                <v-icon right>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="link in menuLinks"
                :key="link.text"
                @click="link.action"
            >
                <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</grid-toolbar>

      <grid-norecords> There is no data available custom </grid-norecords>
    </Grid>
    <!--upload inverters button-->
    <v-dialog v-model="showUploadInvertersModal" width="500">
      <v-card>
        <v-spacer>
          <v-alert
            type="success"
            dismissible
            v-model="showUploadInvertersSuccessAlert"
          >
            Inverter data was successfully uploaded.
          </v-alert>
          <v-alert
            type="error"
            dismissible
            v-model="showUploadInvertersErrorAlert"
          >
            Error: {{ this.uploadInvertersError }}
          </v-alert>
        </v-spacer>
        <v-card-title> Upload Inverters </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="uploadedInverterFile"
            accept="text/csv"
            label="CSV file"
            required
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <a
            :href="''"
            v-text="'Download template'"
            @click.prevent="downloadInvertersCSVTemplate"
          >
          </a>
          <v-spacer></v-spacer>
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-danger"
            outlined
            @click="closeUploadInverters"
          >
            Cancel
          </v-btn>
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            @click="submitUploadInverter"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--for site upload button-->
    <site-upload-modal
      @clicked="newSiteUploaded"
      v-model="showUploadSitesModal"
    />
    <k-dialog 
      v-if="showSiteMergeModal" 
      :width="1050"
      :height="750"
      :title="'Merge Site Configuration'"
      @close="showSiteMergeModal = false"
      >
      <site-merge></site-merge>
    </k-dialog>
    <k-dialog
      v-if="visibleDialog"
      :title="'Site Details'"
      class="ml-4"
      :width="1400"
      :height="750"
      id="details_modal"
      @close="toggleDialog"
    >
      <div id="details_div">
        <v-container id="details_container" style="padding: 3px">
          <sitedetails
            :siteId="this.selectedID"
            :insideSiteModal="true"
          ></sitedetails>
        </v-container>
      </div>
    </k-dialog>
    <!-- column dialog -->
    <v-dialog
      v-model="columnDialog"
      width="300"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Columns
          <v-spacer></v-spacer>
                 <v-btn icon @click="columnDialog = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
        </v-card-title>

        <v-card-text>
          <!-- <p>{{ selected_fields }}</p> -->
          <div v-for="f in site_fields">
              <v-checkbox v-model="selected_fields" :label="`${f.title}`" :value="f"></v-checkbox>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveColumns"
          >
            Save Columns
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import CommandCell from "./CommandCell";

import SiteAccountDropDownCell from "./SiteAccountDropDownCell.vue";
import { Dialog } from "@progress/kendo-vue-dialogs";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";
import SmDropDownCellVue from "./SmDropDownCell.vue";
import RgDropDownCellVue from "./RgDropDownCell.vue";
import BpDropDownCellVue from "./BpDropDownCell.vue";
import Templates from "../CsvTemplate.vue";
import SiteDetail from "../SiteDetail/SiteDetail.vue";
import SiteUploadModal from "./SiteUploadModal.vue";
import SiteMerge from "./SiteMerge.vue";
// import InverterList from "./InverterList.vue";

(VueToast);

export default {
  components: {
    Grid: Grid,
    // InverterList,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    "site-merge": SiteMerge,
    ddcell: SiteAccountDropDownCell,
    smcell: SmDropDownCellVue,
    rgcell: RgDropDownCellVue,
    bpcell: BpDropDownCellVue,
    custom: CommandCell,
    "k-dialog": Dialog,
    Templates,
    sitedetails: SiteDetail,
    SiteUploadModal,
  },
  data: function () {
    return {
      suAdditionalColumns: [],
      isVisible: true,
      filter: null,
      sort: null,
      pageable: { pageSizes: [10, 20, 50, 100, 1000] },
      gridData: [],
      skip: 0,
      take: 20,
      random: 1,
      pageSize: 20,
      updatedData: [],
      selectedID: 1,
      selectedField: "selected",
      //booleans for new buttons
      showUploadInvertersModal: false,
      showSiteMergeModal: false,
      showUploadInvertersSuccessAlert: false,
      showUploadInvertersErrorAlert: false,
      expandedInverterDetails: [],
      uploadInvertersError: "",
      uploadedInverterFile: null,
      showUploadSitesModal: false,
      editID: null,
      visibleDialog: false,
      valid: true,
      newSite: {
        name: "",
        customerName: "",
        customerAccountName: "",
      },
      columnDialog: false,
      sort: [{ field: "name", dir: "asc" }],
      site_fields: [
        { field: "solar_module_wattage", title: "DC Size (kW)", width: "150px"},
        { field: "ac_capacity", title: "AC Size (kW)", width: "150px"},
        { field: "monitoring_system", title: "Monitoring System", width: "150px"},
        { field: "customerAccountName", title: "Account", width: "150px" },
        { field: "customerName", title: "Customer", width: "150px" },
        { field: "secondary_monitoring_system",title: "Secondary Monitor", width: "150px" },
        // { field: "reporting_group.name",title: "Reporting Group", width: "150px"},
        { field: "billing_plan.name", title: "Billing Plan", width: "150px" },
        { field: "annual_visit", title: "Annual visit Y/N", width: "150px"},
        { field: "managingCases", title: "Case Management", width: "150px"},
        { field: "performanceGuarantee", title: "Performance Guarantee", width: "150px"},
        { field: "annual_fee", title: "Annual Fee", width: "100px" },
        { field: "exp_date", format: "{0:d}", title: "Exp Date", width: "150px"},
        { field: "utility_rate", title: "Utility Rate", width: "150px" } ,
        { field: "email", title: "Email", width: "150px"},
        { field: "phone", title: "Phone", width: "150px"},
        { field: "monitoring_url", title: "Monitoring URL", width: "150px"},
        { field: "install_year", title: "Install year", width: "150px"},
        { field: "inverter_manufacturer", title: "Inv Manufacturer", width: "150px"},
        { field: "address", title: "Address", width: "150px"},
        { field: "city", title: "City", width: "150px" },
        { field: "state", title: "State", width: "150px" },
        { field: "zip", title: "Zip", width: "150px" },
        { field: "shade_winter", title: "Shade Winter", width: "150px" },
        { field: "shade_summer", title: "Shade Summer", width: "150px" },
        { field: "shade_fallspring", title: "Shade FallSpring", width: "150px" },
        { field: "monitoring_site_id", title: "Monitor Site ID", width: "150px" },
        { field: "account_id", title: "Account ID", width: "150px" },
        { field: "lat", title: "Lat", width: "150px" },
        { field: "lng", title: "Long", width: "150px" },
        { field: "check_frequency", title: "Check Frequency", width: "150px" },
        { field: "is_archived", title: "Is Archived", width: "150px" },
        { field: "site_plans", title: "Site Plans", width: "150px" },
        { field: "monitoring_link", title: "Monitor Link", width: "150px" },
        { field: "contact", title: "Contact", width: "150px" },
        { field: "notes", title: "Notes", width: "150px" },
        { field: "is_paused", title: "Is Paused", width: "150px" },
        { field: "annual_production_estimate", title: "Ann Prod Estimate", width: "150px" },
        { field: "component_2", title: "Component 2", width: "150px" },
        { field: "internal_id", title: "Internal ID", width: "150px" },
        { field: "mowing_required", title: "Mowing Required", width: "150px" },
        { field: "master_site_id", title: "Master Site", width: "150px" },
      ],
      selected_fields: [],
      // menuLinks: [
      //       { text: "Export to Excel", action: () => this.exportExcel() },
      //       { text: "Link 2", action: () => { /* Do something */ } },
      //       // ... other links
      //   ],
    };
  },
  computed: {
    inverterDetail() {
      // console.log(item)
      let invDetails = this.expandedInverterDetails;
      return invDetails;
    },
    result: {
      get: function () {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    user: function () {
      return this.$store.getters.currentUser;
    },
    columns: function () {
      let baseColumns = [
        { field: "id", width: "100px", title: "ID" },
        { field: "name", width: "175px", cell: "myCellTemplate" },
      ];
      // add in additional columns

      if (this.$store.state.alerts.userColumns.length > 0) {
        this.$store.state.alerts.userColumns.forEach((element) => {
          baseColumns.push(element);
        });
      }
      return baseColumns;
    },
    menuLinks() {
      let links = [
        { text: "Export to Excel", action: () => this.exportExcel() },
        { text: "Select table columns", action: () => this.showColumnModal() },
        { text: "Merge Sites", action: () => this.viewSiteMergeModal() },
      ];

      // Check for user role and visibility
      if (this.isVisible && this.user.claims.user_role === "super_user") {
        links.push({ text: "Upload Sites", action: this.startUploadSites });
        links.push({
          text: "Upload Inverters",
          action: this.startUploadInverters,
        });
      }

      return links;
    },
  },
  created: function () {
    this.selected_fields = this.$store.state.alerts.userColumns;
    this.getData();
  },
  mounted() {
    document.title = "Sites"
  },
  methods: {
    rowClass({ dataItem }) {
      if (dataItem.column1 === dataItem.column2) {
        return 'highlight-row';  // Return the class name for the rows that meet the condition.
      }
      return '';
    },
    cellFunction: function (h, trElement , defaultSlots, props ) {
      const available = !props.dataItem.Discontinued;
      const green = {
          backgroundColor: "rgb(55, 180, 0,0.32)",
      };
      const white = {
        backgroundColor: 'white',
      };
      const red = {
          backgroundColor: "rgb(243, 23, 0, 0.32)",
      };
      const trProps = {
          style: (props.dataItem.id === props.dataItem.master_site_id) ? red : white,
      };

      return h(
          'tr',
          trProps,
          defaultSlots);
    },
    filterChange: function (ev) {
      this.filter = ev.filter;
    },
    getData: function () {
      NProgress.start();
      Service.getSitesGrid()
        .then((response) => {
          const sites = response.data.map((s) => {
            if (s.exp_date != null) {
              s.exp_date = new Date(s.exp_date);
            }
            return s;
          });
          this.gridData = sites;
          NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    onRowClick(event) {
      this.selectedID = event.dataItem.id;
      console.log(`selected ${this.selectedID}`);
      this.toggleDialog();
    },
    ddChange: function (e, dataItem) {
      console.log(`ddChange ${e}, data: ${dataItem}`);
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, dataItem);
      item.customerAccountName = e.target.value.name;
      item.customerAccountId = e.target.value.id;
      item.account_id = e.target.value.id;
      this.gridData = updatedData;
    },
    newSiteUploaded(args) {
      console.log("new site event");
      this.showUploadSitesModal = false;
      this.getData();
      Vue.$toast.success(`Site uploaded`, {
        position: "top-right",
        duration: 4000,
      });
    },
    viewSiteMergeModal() {
      this.showSiteMergeModal = true;
    },
    smChange: function (e, dataItem) {
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, dataItem);
      item.secondary_monitoring_system = e.target.value;
      this.gridData = updatedData;
    },
    rgChange: function (e, dataItem) {
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, dataItem);
      item.reporting_group_id = e.target.value.id;
      item.reporting_group = e.target.value;
      this.gridData = updatedData;
    },
    bpChange: function (e, dataItem) {
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, dataItem);
      item.billing_plan_id = e.target.value.id;
      item.billing_plan = e.target.value;
      this.gridData = updatedData;
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    showColumnModal() {
      this.columnDialog = true;
    },
    getRandomInt() {
      return Math.floor(Math.random() * 10000);
    },
    dataStateChange: function (event) {
      console.log("dataStateChange...");
    },
    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    insert() {
      const dataItem = { inEdit: true };
      const newSites = this.gridData.slice();
      newSites.unshift(dataItem);
      this.update(newSites, dataItem);
      this.gridData = newSites;
      this.isVisible = false;
    },
    edit: function (e) {
      let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
      let updated = Object.assign({}, this.gridData[index], { inEdit: true });
      this.gridData.splice(index, 1, updated);
    },
    save: function (e) {
      let customerID = e.dataItem.id;
      let index = this.gridData.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(e.dataItem) ||
          (customerID && c.id === customerID)
      );
      let item = this.gridData[index];
      let updated = Object.assign(this.update(this.gridData.slice(), item), {
        inEdit: undefined,
      });
      if (updated.id == undefined) {
        NProgress.start();
        Service.createSiteNew(updated)
          .then((response) => {
            Vue.$toast.success(`Site created`, {
              position: "top-right",
              duration: 4000,
            });
            updated.id = response.data.id;

            // update annual_visit to show Y/N letter instead of true/false
            let annual_visit = updated.annual_visit;
            updated.annual_visit = annual_visit ? "Y" : "N";

            this.gridData.splice(index, 1, updated);
            NProgress.done();
          })
          .catch((error) => {
            NProgress.done();
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        NProgress.start();
        Service.updateSiteGrid(updated.id, updated)
          .then((response) => {
            Vue.$toast.success(`Site updated`, {
              position: "top-right",
              duration: 4000,
            });

            // update annual_visit to show Y/N letter instead of true/false
            let annual_visit = updated.annual_visit;
            updated.annual_visit = annual_visit ? "Y" : "N";

            this.gridData.splice(index, 1, updated);
            NProgress.done();
          })
          .catch((error) => {
            NProgress.done();
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    saveColumns() {
      // this.$store
      this.$store.dispatch('setUserColumns', this.selected_fields);
    },
    selectColumns() {
      this.suAdditionalColumns.push('zip');
    },
    update(data, item, remove) {
      let updated;
      let customerID = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (customerID && c.id === customerID)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }

      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );

        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      Service.deleteSite(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Site removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      this.isVisible = true;
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
    //new methods for buttons
    startUploadSites() {
      this.showUploadSitesModal = true;
    },
    startUploadInverters() {
      this.showUploadInvertersErrorAlert = false;
      this.showUploadInvertersSuccessAlert = false;
      this.showUploadInvertersModal = true;
    },
    closeUploadInverters() {
      this.showUploadInvertersModal = false;
    },
    getInverterDescription(inverterMetrics, index) {
      return inverterMetrics.description || `inverter ${index + 1}`;
    },
    downloadInvertersCSVTemplate() {
      Service.downloadInvertersCSVTemplate().then(
        this.handleSuccessfulInvertersTemplateDownload
      );
    },
    submitUploadInverter() {
      if (this.uploadedInverterFile) {
        Service.uploadInverterData(this.uploadedInverterFile)
          // .then(this.handleSuccessfulInvertersUpload)
          .then((response) => {
            console.log("this worked?");
            this.showUploadInvertersModal = false;
            this.getData();
            Vue.$toast.success(`Inverters uploaded`, {
              position: "top-right",
              duration: 4000,
            });
          })
          .catch((error) => {
            // this.handleErroneousInvertersUpload(error.response.data.error);
            console.log("Error uploading inverters:", error.response);
            Vue.$toast.error(`Error uploading inverters`, {
              position: "top-right",
              duration: 4000,
            });
          });
      } else {
        this.handleErroneousInvertersUpload("No file given");
      }
    },
    handleSuccessfulInvertersUpload(response) {
      const _upserted = response.data.filter((node) => node.status === "ok");
      const withErrors = response.data.filter(
        (node) => node.status === "error"
      );

      if (withErrors.length > 0) {
        this.handleErroneousInvertersUpload(
          this.createErrorMessage(withErrors)
        );
      } else {
        this.showUploadInvertersSuccessAlert = true;
      }
    },
    handleErroneousInvertersUpload(error) {
      this.uploadInvertersError = error;
      this.showUploadInvertersErrorAlert = true;
    },
    handleSuccessfulInvertersTemplateDownload(response) {
      this.handleCSVDownload(response, "inverters.csv");
    },
    handleCSVDownload(response, filename) {
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    exportExcel() {
      saveExcel({
        data: this.dataToExport(),
        fileName: "sites.xlsx",
        columns: this.columns,
      });
    },
    dataToExport() {
      if (this.filter) {
        return this.result;
      } else {
        return this.gridData;
      }
    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
      if(!this.visibleDialog) {
        console.log('close')
        this.getData();
      }
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style>
.k-toolbar {
  display: block;
}

.k-toolbar #add_new_btn {
  float: left;
}

.k-toolbar #cancel_changes_btn {
  float: left;
  margin-left: 2%;
}

.k-toolbar #upload_sites_btn {
  float: left;
}

.k-toolbar #upload_inverters_btn {
  float: left;
  margin-left: 1%;
}

.k-toolbar #import_hist_btn {
  float: left;
  margin-left: 1%;
}

.k-toolbar #export_btn {
  float: right;
}

.k-grid .k-grid-header .k-header {
  white-space: normal;
  vertical-align: middle;
}
#inverter_link_button {
  float: left;
  margin-left: 5%;
}
/* #details_modal {
  background-color:#f0f2f5;
} */
#details_div {
  background-color:#f0f2f5;
}
/* #details_container {
  background-color:#f0f2f5;
} */
#monitoring_link_button {
  float: left;
  margin-left: 5%;
}
#site_plans_button {
  float: left;
  margin-left: 5%;
}
.link-button {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.highlight-row {
  background-color: yellow;
}
</style>